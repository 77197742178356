var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "brandStoryTile" },
    [
      _c(
        "router-link",
        {
          staticClass: "link",
          attrs: { to: "/brandStory?id=" + _vm.brandStory.id },
          nativeOn: {
            click: function ($event) {
              return _vm.setSelectedBrandStory.apply(null, arguments)
            },
          },
        },
        [
          _c("AssetDownload", {
            class: _vm.height,
            attrs: {
              asset: _vm.brandStory.thumbnailAsset,
              forceImage: _vm.brandStoryImage,
              isStoryTile: true,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.setSelectedBrandStory.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "middleCopy" },
        [
          _c(
            "router-link",
            {
              staticClass: "link",
              attrs: { to: "/brandStory?id=" + _vm.brandStory.id },
              nativeOn: {
                click: function ($event) {
                  return _vm.setSelectedBrandStory.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.brandStory.subject)),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "bottomSection" }, [
        _c("div", { staticClass: "postedBy" }, [
          _vm.isPostedByUserNotAdmin
            ? _c(
                "div",
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.copy.postedBy) + "\n        "
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: "profile",
                          query: { id: _vm.brandStory.submittedByUser.id },
                        },
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.setProfileViewUser.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.brandStory.submittedByUser.namePreferred)
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm.brandStory.brandStoryLikesCount > 0
          ? _c(
              "div",
              { staticClass: "bs-like" },
              [
                _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: ["fal", "heart"] },
                }),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.brandStory.brandStoryLikesCount) +
                    "\n    "
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }